//	Typography

.gigantic, .huge, .large, .bigger, .big,
h1, h2, h3, h4, h5, h6 {
  color: $accent-color;
  font-weight: bold; }

.gigantic {
  font-size: 68px;
  line-height: 1.05;
  letter-spacing: -1px; }

.huge, h1 {
  font-size: 40px;
  line-height: 1.14; }

.large, h2 {
  font-size: 33px;
  line-height: 1.14; }

.bigger, h3 {
  font-size: 28px;
  line-height: 1.38; }

.big, h4 {
  font-size: 23px;
  line-height: 1.38; }

.big2, h5 {
  font-size: 19px;
  line-height: 1.38; }

h6 {
  font-size: 16px;
  line-height: 1.38; }

.small, small {
  font-size: 12px;
  line-height: 1.2; }



p {
  margin: 0 0 20px 0; }

em {
  font-style: italic; }

strong {
  font-weight: bold; }

hr {
  border: solid #ddd;
  border-width: 1px 0 0;
  clear: both;
  margin: 10px 0 30px;
  height: 0; }



// Links

a {
  color: $link-color;
  text-decoration: underline;
  outline: 0; }

a:hover, a:focus {
  color: darken($link-color, 20%); }

// Selection colors (easy to forget)
//
// ::selection
//   background: $selection-color
//
// ::-moz-selection
//   background: $selection-color
//
// img::selection
//   background: transparent
//
// img::-moz-selection
//   background: transparent
//
// body
//   -webkit-tap-highlight-color: $selection-color



// Basic Styles

body {
  background: $bg-color;
  font-size: 16px;
  line-height: 1.5;
  font-family: 'Source Sans Pro', sans-serif;
  color: $text-color;
  -webkit-text-size-adjust: 100%; }

