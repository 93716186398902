.contact-section {
  padding: 20px 20px;
  @include clearfix; }

.contact-info p {
  margin: auto;
  padding: initial; }

.contact-links {
  max-width: 960px;
  @include display(flex);
  flex-direction: row;
  margin: 0px;
  flex-wrap: wrap;
  @include align-items(center);
  @include justify-content(space-between);

  a {
    text-align: center;
    margin: 0 auto; } }

.contact-icon {
  @include size(32px);
  margin: 3px;
  background: $bg-color;
  float: left;
  @include clearfix; }

.contact-link {
  margin: 0 auto;
  float: left;
  @include display(flex);
  @include align-items(center);
  @include clearfix;
  padding: 10px 10px;
  &:last-child {
    margin-right: 0; } }

@media screen and (max-width: 650px) {
  .contact-links {
    display: block;
    width: 100;
    text-align: center;
    float: none;
    margin-left: auto;
    margin-right: auto;
    @include display(flex);
    flex-direction: row;
    flex-wrap: wrap;
    @include align-items(center);
    @include justify-content(space-around);
    .contact-link {
      @include display(flex);
      @include align-items(center);
      @include clearfix; }
    .contact-info {
      display: block;
      width: auto;
      margin-left: auto;
      margin-right: auto;
      text-align: center; } } }

@media screen and (max-width: 300px) {
  .contact-links {
    display: block; } }

