
div.content-width {
  max-width: 960px;
  margin: 0 auto;
  header, article {
    margin-top: -50px; }
  header {
    @extend section;
    padding-top: 0;
    padding-bottom: 0; } }

ol, ul {
  padding-left: 30px; }

.post_subtitle {
  @extend .big;
  font-style: italic; }

.bloglist dt {
  @extend .big2; }

.bibliography {
  .author:after {
    content: ","; }
  .title {
    quotes: '\201c' '\201d'; }
  .title:before {
    content: open-quote; }
  .title:after {
    content: close-quote; }
  .conf {
    font-style: italic; }
  .conf:after {
    content: ","; }
  .where:after {
    content: ","; }
  .when:after {
    content: "."; } }

dl.pub-info {
  width: 100%;
  dt {
    font-weight: bold;
    width: 6em;
    float: left;
    clear: left; }
  dt:after {
    content: ":"; }
  dd {
    margin-left: 7em; } }

dl:after {
  clear: left;
  display: block;
  content: ""; }

article section {
  padding-top: 0;
  margin-top: 20px; }

/*
 * Ptyles for printing a blog post
 */

@media print {
  body {
    background: white;
    > header {
      display: none; } }

  t .webonly {
    display: none; }

  article {
    div.title {
      page-break-after: avoid; }

    span.image img {
      max-height: 10cm; } } }

