footer {
  height: 100px;
  max-width: 960px;
  margin: 0 auto;
  float: none;
  clear: both;

  .content-wrap {
    margin: 30px 40px;
    float: right;

    .copyright {
      margin: 0;
      @extend %small-bold;
      font-size: 10px;
      p {
        max-width: 480px; }
      a {
        text-decoration: underline;
        font-size: 10px;
        &:hover {
          color: rgba(0,0,0,1); } } } } }
