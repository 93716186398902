// Sky blue
$primary-color: #4da2cc;

// Related colors
// #b74dcc #62cc4d #cc774d

// Trees in shadow
$accent-color: #005374;

// Related colors
// #b74dcc #cc774d #62cc4d

// Snow white
$bg-color: rgb(243,243,243);

$altbg-color: #d3d7da;
$selection-color: auto;
$text-color: #2E2D33;

$link-color: $accent-color;
