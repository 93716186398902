
div.title {
  font-size: 16px;
  font-weight: bold;
  color: $accent-color; }

div.listingblock > div.content {
  border-width: 2px;
  border-color: $accent-color;
  background: $altbg-color; }

// div.listingblock
//   border: thin black solid
//   padding: 0 1em 0
//   margin-bottom: 20px

table {
  td, th {
    padding: 0.1em;
    p {
      margin: auto; } }
  caption.title {
    margin-top: auto; } }

// Undo docbook style
div.quoteblock {
  border: none;
  color: inherit;
  p {
    margin: 0; }
  blockquote {
    margin: 0; } }

div.right {
  float: right;
  clear: right; }

div.left {
  float: left;
  clear: left; }

div.center {
  margin: 0 auto;
  width: -moz-min-content;
  width: min-content; }

div.flexcenter {
  clear: both; }

div.flexcenter div.content {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  table {
    margin-top: 0; } }

div.floatright {
  float: right;
  clear: right;
  margin-left: 0.5em;
  table {
    margin-top: 0; } }

div.floatright_3in {
  @extend div, .floatright;
  max-width: 3in; }

article {
  span.image img {
    max-width: 100%; }
  dl dt {
    font-weight: bold; } }

li p:only-child {
  margin-bottom: 0; }

