
$background_bottom_margin_offset: -112px;

.header-area {
  margin: 0 auto $background_bottom_margin_offset;
  max-width: 960px;
  height: 337px;
  background: url(../img/snow.jpeg) center top;
  background-size: 100% auto;
  background-repeat: no-repeat;

  @include display(flex);
  flex-direction: row;
  flex-wrap: wrap;
  @include align-items(baseline);
  @include justify-content(space-between);
  @include align-content(flex-start);

  nav {
    margin: 15px 30px;
    a {
      display: inline-block;
      margin-left: 20px;
      text-decoration: none;

      @extend %small-bold;

      &:hover {}
      &:first-child {
        margin-left: 0px; } } }

  h1 {
    margin: 15px 30px;
    font: {
      size: 32px;
      weight: 700;
      family: Nunito, sans-serif; }
    text-transform: uppercase;
    a {
      font-size: inherit;
      font-weight: inherit;
      text-decoration: none; } } }

$background_aspect: 960px / 337px;
// A percentage of the height.
$background_bottom_margin_ratio: ($background_bottom_margin_offset / 337px) / $background_aspect;

@media screen and (max-width: 800px) {
  .header-area {
    margin: 0 auto 800px * $background_bottom_margin_ratio;
    max-width: 800px;
    height: 800px / $background_aspect; } }

@media screen and (max-width: 775px) {
  .header-area {
    height: auto;
    margin: 0 auto 0;
    background: none; } }

