section {
  max-width: 960px;
  margin: 0 auto;
  float: none;
  padding: 20px 20px; }


.about-lockup {
  max-width: 860px;
  @include display(flex);
  margin: 0 auto;
  @include align-items(center);
  @include justify-content(space-around);

  .face {
    order: 1;
    margin: auto 2ex; }

  .about-txt {
    @include size(500px auto);
    order: 2;
    text-align: left;
    margin: auto 2ex; } }

@media screen and (max-width: 800px) {
  .face {
    width: 150px; } }

@media screen and (max-width: 650px) {
  .about-lockup {
    display: block;
    text-align: center;
    div {
      display: inline-block; }
    .face {
      display: block;
      background-postion: center center;
      background-repeat: no-repeat;
      margin-left: auto;
      margin-right: auto; }
    .about-txt {
      display: block;
      width: auto;
      margin-left: auto;
      margin-right: auto;
      h3 {
        margin-left: -11px; } } }
  .blurb {
    max-width: 600px;
    margin: 0 auto; } }

